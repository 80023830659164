body {
  margin: 0;
  font-family:  "Helvetica Neue", Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:  "Helvetica Neue", Helvetica;
}
